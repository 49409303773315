import React, {useContext, useEffect} from "react"

import Layout from "../components/layout"
import {UserContext} from "../context/UserContext";
import {userPool} from "../utils/cognito-utils";
import SEO from "../components/seo";

const Logout = () => {
  const userContext = useContext(UserContext);
  useEffect(() => {
    if (userContext.user) {
      userPool.getCurrentUser().signOut()
      userContext.setUser(null);
    }
  }, [userContext]);

  return (
    <Layout>
      <SEO title="Logout" keywords={[`gatsby`, `application`, `react`]}/>
      <div
        className="font-sans font-light px-2 md:px-32 py-16 text-xl text-center">{userContext.user ? 'Logging out' : 'Thank you for using LookStyler. You have been successfully logged out from your account'}</div>
    </Layout>
  );
}

export default Logout
